//Add strings


gdprCookieNoticeLocales.lt = {

  description: ' Mes naudojame cookies norėdami pasiūlyti geresnį naršymą, suasmeninti turinį ir skelbimus, pateikti socialinės žiniasklaidos funkcijas ir analizuoti mūsų srautą. Sužinokite apie tai, kaip mes naudojame cookies ir kaip galite juos valdyti spustelėdami Cookies nustatymai. Jūs sutinkate su mūsų cookies, jei ir toliau naudositės šia svetaine.',

  settings: ' Cookies nustatymai ',

  accept: ' Priimkite cookies ',

  statement: ' Mūsų cookies pareiškimas ',

  save: ' Išsaugoti nustatymus ',

  always_on: ' Visada ',

  cookie_essential_title: ' Esminiai svetainės cookies ',

  cookie_essential_desc: ' Būtini cookies padeda tinklalapį naudoti, nes įgalina pagrindines funkcijas, tokias kaip naršymas puslapyje ir prieiga prie saugių svetainės sričių. Svetainė negali tinkamai veikti be šių cookies.',

  cookie_performance_title: ' Spektaklio cookies',

  cookie_performance_desc: ' Šie cookies yra naudojami siekiant pagerinti mūsų svetainių našumą ir funkcionalumą, tačiau nėra būtini jų naudojimui. Pavyzdžiui, jame saugoma jūsų pasirinkta kalba arba regionas, kuriame esate.',

  cookie_analytics_title: 'Analytics cookies',

  cookie_analytics_desc: ' Mes naudojame analizės cookies, kad padėtume įvertinti, kaip vartotojai sąveikauja su svetainės turiniu. Tai padeda mums tinkinti mūsų svetaines ir programas jums, kad padidintumėte jūsų patirtį.',

  cookie_marketing_title: ' Rinkodaros cookies ',

  cookie_marketing_desc: ' Šie cookies naudojami tam, kad reklaminės žinutės taptų tinkamesnės jums ir jūsų interesams. Ketinama rodyti skelbimus, kurie yra aktualūs ir patrauklūs individualiam vartotojui, taigi yra vertingesni leidėjams ir trečiųjų šalių reklamuotojams.'

}
