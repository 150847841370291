//Add strings


gdprCookieNoticeLocales.cr = {

    description: ' Koristimo kolačiće kako bismo vam ponudili bolje iskustvo pregledavanja, personalizirali sadržaj i oglase, pružili značajke društvenih medija i analizirali naš promet. Pročitajte o tome kako koristimo kolačiće i kako ih možete kontrolirati klikom na Postavke cookies. Prihvaćate naše kolačiće ako nastavite koristiti ovu web stranicu.',

    settings: ' Postavke cookies',

    accept: ' Prihvati kolačiće',

    statement: ' Naša izjava o cookiesma',

    save: ' Spremi postavke',

    always_on: ' Uvijek na',

    cookie_essential_title: ' Osnovni cookies web stranice',

    cookie_essential_desc: ' Potrebni cookies pomažu učiniti web mjesto upotrebljivim omogućavajući osnovne funkcije poput navigacije stranicama i pristupa sigurnim područjima web stranice. Bez ovih cookies web stranica ne može pravilno funkcionirati.',

    cookie_performance_title: ' Cookies performansi ',

    cookie_performance_desc: ' Ovi se cookies koriste za poboljšanje performansi i funkcionalnosti naših web stranica, ali nisu bitni za njihovu upotrebu. Na primjer, pohranjuje željeni jezik ili regiju u kojoj se nalazite.',

    cookie_analytics_title: ' Kolačiće Analytics ',

    cookie_analytics_desc: ' Koristimo kolačiće analitike kako bismo nam pomogli u mjerenju interakcije korisnika sa sadržajem web mjesta, što nam pomaže da prilagodimo naše web stranice i aplikacije za vas kako bismo poboljšali vaše iskustvo.',

    cookie_marketing_title: 'Marketing cookies',

    cookie_marketing_desc: ' Ovi se cookies koriste kako bi se reklamne poruke učinile relevantnijima za vas i vaše interese. Namjera je prikazati oglase koji su relevantni i privlačni za pojedinog korisnika, a samim time i vrijedniji za izdavače i treće oglašavače.'

}
