//Add strings


gdprCookieNoticeLocales.es = {

  description: ' Utilizamos cookies para ofrecerle una mejor experiencia de navegación, personalizar contenido y anuncios, proporcionar funciones de redes sociales y analizar nuestro tráfico. Lea sobre cómo usamos las cookies y cómo puede controlarlas haciendo clic en Configuración de cookies. Usted acepta nuestras cookies si continúa utilizando este sitio web.',

  settings: ' Configuraciones de cookies ',

  accept: ' Acepto galletas ',

  statement: ' Nuestra declaración de cookies ',

  save: ' Guardar ajustes ',

  always_on: ' Siempre en ',

  cookie_essential_title: ' Cookies esenciales del sitio web ',

  cookie_essential_desc: ' Las cookies necesarias ayudan a que un sitio web sea utilizable al habilitar funciones básicas como la navegación de páginas y el acceso a áreas seguras del sitio web. El sitio web no puede funcionar correctamente sin estas cookies.',

  cookie_performance_title: ' Cookies de rendimiento ',

  cookie_performance_desc: ' Estas cookies se utilizan para mejorar el rendimiento y la funcionalidad de nuestros sitios web, pero no son esenciales para su uso. Por ejemplo, almacena su idioma preferido o la región en la que se encuentra.',

  cookie_analytics_title: ' Cookies analíticas ',

  cookie_analytics_desc: ' Utilizamos cookies de análisis para ayudarnos a medir cómo los usuarios interactúan con el contenido del sitio web, lo que nos ayuda a personalizar nuestros sitios web y aplicaciones para mejorar su experiencia.',

  cookie_marketing_title: ' Cookies de marketing ',

  cookie_marketing_desc: ' Estas cookies se utilizan para hacer que los mensajes publicitarios sean más relevantes para usted y sus intereses. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para editores y anunciantes externos.'

}
