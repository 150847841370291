//Add strings


gdprCookieNoticeLocales.de = {

  description: ' Wir verwenden Cookies, um Ihnen ein besseres Surferlebnis zu bieten, Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien bereitzustellen und unseren Datenverkehr zu analysieren. Lesen Sie, wie wir Cookies verwenden und wie Sie sie steuern können, indem Sie auf Cookie-Einstellungen klicken. Sie stimmen unseren Cookies zu, wenn Sie diese Website weiterhin nutzen.',

  settings: ' Cookie-Einstellungen',

  accept: ' Cookies akzeptieren ',

  statement: ' Unsere Cookie-Erklärung',

  save: ' Einstellungen speichern ',

  always_on: ' Immer auf ',

  cookie_essential_title: ' Wichtige Website-Cookies ',

  cookie_essential_desc: ' Notwendige Cookies helfen dabei, eine Website nutzbar zu machen, indem sie grundlegende Funktionen wie die Seitennavigation und den Zugriff auf sichere Bereiche der Website ermöglichen. Die Website kann ohne diese Cookies nicht ordnungsgemäß funktionieren.',

  cookie_performance_title: ' Leistungscookies ',

  cookie_performance_desc: ' Diese Cookies werden verwendet, um die Leistung und Funktionalität unserer Websites zu verbessern, sind jedoch für deren Verwendung nicht wesentlich. Beispielsweise wird Ihre bevorzugte Sprache oder die Region gespeichert, in der Sie sich befinden.',

  cookie_analytics_title: ' Analytics-Cookies ',

  cookie_analytics_desc: ' Wir verwenden Analyse-Cookies, um zu messen, wie Benutzer mit Website-Inhalten interagieren. Auf diese Weise können wir unsere Websites und Anwendungen für Sie anpassen, um Ihre Erfahrung zu verbessern.',

  cookie_marketing_title: ' Marketing-Cookies ',

  cookie_marketing_desc: ' Diese Cookies werden verwendet, um Werbebotschaften für Sie und Ihre Interessen relevanter zu machen. Ziel ist es, Anzeigen zu schalten, die für den einzelnen Nutzer relevant und ansprechend und damit für Publisher und Werbekunden von Drittanbietern wertvoller sind.'

}
