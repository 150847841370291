//Add strings


gdprCookieNoticeLocales.it = {

  description: 'Utilizziamo i cookie per offrirti una migliore esperienza di navigazione, personalizzare contenuti e annunci, fornire funzionalità di social media e analizzare il nostro traffico. Leggi come utilizziamo i cookie e come puoi controllarli facendo clic su Impostazioni cookie. Acconsenti ai nostri cookie se continui a utilizzare questo sito Web.',

  settings: ' Impostazioni dei cookie ',

  accept: ' Accetta i cookie ',

  statement: ' La nostra informativa sui cookie',

  save: ' Salva le impostazioni',

  always_on: ' Sempre acceso ',

  cookie_essential_title: ' Cookie essenziali del sito Web ',

  cookie_essential_desc: ' I cookie necessari aiutano a rendere utilizzabile un sito Web abilitando funzioni di base come la navigazione della pagina e laccesso ad aree sicure del sito Web. Il sito Web non può funzionare correttamente senza questi cookie.',

  cookie_performance_title: ' Cookie prestazionali ',

  cookie_performance_desc: ' Questi cookie vengono utilizzati per migliorare le prestazioni e la funzionalità dei nostri siti Web ma non sono essenziali per il loro utilizzo. Ad esempio, memorizza la tua lingua preferita o la regione in cui ti trovi.',

  cookie_analytics_title: ' Cookie analitici',

  cookie_analytics_desc: ' Utilizziamo i cookie analitici per aiutarci a misurare il modo in cui gli utenti interagiscono con i contenuti del sito Web, il che ci aiuta a personalizzare i nostri siti Web e le applicazioni per te al fine di migliorare la tua esperienza.',

  cookie_marketing_title: ' Cookie di marketing ',

  cookie_marketing_desc: ' Questi cookie vengono utilizzati per rendere i messaggi pubblicitari più pertinenti per te e i tuoi interessi. Lintenzione è quella di visualizzare annunci pertinenti e coinvolgenti per il singolo utente e quindi più preziosi per editori e inserzionisti di terze parti.'

}
