//Add strings


gdprCookieNoticeLocales.et = {

  description: ' Kasutame cookies, et pakkuda teile paremat sirvimiskogemust, isikupärastada sisu ja reklaame, pakkuda sotsiaalmeedia funktsioone ja analüüsida meie liiklust. Lugege, kuidas me cookies kasutame ja kuidas saate neid kontrollida, klõpsates küpsise sätteid. Nõustute meie küpsistega, kui jätkate selle veebisaidi kasutamist.',

  settings: ' Prääniku seaded',

  accept: ' Nõustu küpsistega',

  statement: ' Meie küpsiste väljavõte',

  save: ' Salvesta sätted',

  always_on: ' Alati olemas',

  cookie_essential_title: ' Olulised veebi cookies ',

  cookie_essential_desc: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',

  cookie_performance_title: ' Performance cookies',

  cookie_performance_desc: ' Neid cookies kasutatakse meie veebisaitide toimivuse ja funktsionaalsuse parandamiseks, kuid nende kasutamine pole hädavajalik. Näiteks salvestab see teie eelistatud keele või piirkonna, kus viibite.',

  cookie_analytics_title: ' Analyticsi cookies',

  cookie_analytics_desc: 'Kasutame analüüsi cookies, et aidata meil mõõta, kuidas kasutajad veebisaidi sisuga suhtlevad, mis aitab meil teie veebisaite ja rakendusi teie jaoks kohandada, et teie kogemusi paremaks muuta.',

  cookie_marketing_title: ' Turundus cookies ',

  cookie_marketing_desc: ' Neid cookies kasutatakse teie ja teie huvidega seotud reklaamisõnumite asjakohasemaks muutmiseks. Selle eesmärk on kuvada reklaame, mis on asjakohased ja huvitavad üksikkasutaja jaoks ning on seega väärtuslikumad kirjastajate ja kolmandate osapoolte reklaamijate jaoks.'

}
