//Add strings


gdprCookieNoticeLocales.pl = {

  description: ' Używamy plików cookie, aby zapewnić lepszą jakość przeglądania, personalizować treści i reklamy, aby zapewnić funkcje mediów społecznościowych i analizować nasz ruch. Przeczytaj o tym, jak korzystamy z plików cookie i jak możesz je kontrolować, klikając Ustawienia plików cookie. Wyrażasz zgodę na nasze pliki cookie, jeśli nadal korzystasz z tej witryny.',

  settings: ' Ustawienia plików cookie ',

  accept: ' Akceptuje ciasteczka ',

  statement: ' Nasze oświadczenie dotyczące plików cookie ',

  save: ' Zapisz ustawienia ',

  always_on: ' Zawsze włączone ',

  cookie_essential_title: ' Niezbędne pliki cookie na stronie ',

  cookie_essential_desc: ' Niezbędne pliki cookie pomagają uczynić witrynę użyteczną, umożliwiając podstawowe funkcje, takie jak nawigacja po stronie i dostęp do bezpiecznych obszarów witryny. Witryna nie może działać poprawnie bez tych plików cookie.',

  cookie_performance_title: ' Wydajne pliki cookie ',

  cookie_performance_desc: ' Te pliki cookie są używane w celu zwiększenia wydajności i funkcjonalności naszych stron internetowych, ale nie są niezbędne do ich wykorzystania. Na przykład przechowuje preferowany język lub region, w którym się znajdujesz.',

  cookie_analytics_title: ' Pliki cookie Analytics ',

  cookie_analytics_desc: ' Używamy analitycznych plików cookie, aby pomóc nam mierzyć, w jaki sposób użytkownicy wchodzą w interakcje z treściami witryny, co pomaga nam dostosowywać nasze witryny i aplikacje w celu zwiększenia wygody użytkownika.',

  cookie_marketing_title: ' Marketingowe pliki cookie ',

  cookie_marketing_desc: ' Te pliki cookie służą do lepszego dopasowywania komunikatów reklamowych do Ciebie i Twoich zainteresowań. Chodzi o to, aby wyświetlać reklamy, które są trafne i angażujące dla konkretnego użytkownika, a przez to bardziej wartościowe dla wydawców i zewnętrznych reklamodawców.'

}
