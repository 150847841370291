//Add strings


gdprCookieNoticeLocales.sv = {

  description: ' Vi använder cookies för att erbjuda dig en bättre surfupplevelse, anpassa innehåll och annonser, för att erbjuda sociala mediefunktioner och för att analysera vår trafik. Läs om hur vi använder cookies och hur du kan kontrollera dem genom att klicka på Cookie-inställningar. Du samtycker till våra cookies om du fortsätter att använda den här webbplatsen.',

  settings: 'Cookie inställningar ',

  accept: ' Acceptera cookies ',

  statement: ' Vårt cookie-uttalande',

  save: ' Spara inställningar ',

  always_on: ' Alltid på ',

  cookie_essential_title: ' Väsentliga webbplatscookies ',

  cookie_essential_desc: ' Nödvändiga cookies hjälper till att göra en webbplats användbar genom att aktivera grundläggande funktioner som sidnavigering och åtkomst till säkra områden på webbplatsen. Webbplatsen kan inte fungera korrekt utan dessa cookies.',

  cookie_performance_title: ' Prestanda cookies ',

  cookie_performance_desc: ' Dessa cookies används för att förbättra prestanda och funktionalitet på våra webbplatser men är inte väsentliga för deras användning. Till exempel lagrar det ditt språk som du föredrar eller regionen du befinner dig i.',

  cookie_analytics_title: ' Analytics-cookies ',

  cookie_analytics_desc: ' Vi använder analyscookies för att hjälpa oss mäta hur användare interagerar med webbplatsens innehåll, vilket hjälper oss att anpassa våra webbplatser och applikationer för dig för att förbättra din upplevelse.',

  cookie_marketing_title: ' Marknadsföringscookies ',

  cookie_marketing_desc: ' Dessa cookies används för att göra reklammeddelanden mer relevanta för dig och dina intressen. Avsikten är att visa annonser som är relevanta och engagerande för den enskilda användaren och därmed mer värdefulla för utgivare och tredjepartsannonsörer.'

}
