//Add strings


gdprCookieNoticeLocales.ga = {

  description: ' Úsáidimid cookies chun eispéireas brabhsála níos fearr a thairiscint duit, ábhar agus fógraí a phearsanú, chun gnéithe meán sóisialta a sholáthar agus chun ár dtrácht a anailísiú. Léigh faoin gcaoi a n-úsáidimid cookies agus conas is féidir leat iad a rialú trí Shocruithe Cookies a chliceáil. Toilíonn tú lenár bhcookies má leanann tú ag úsáid an láithreáin ghréasáin seo.',

  settings: ' Socruithe cookies ',

  accept: ' Glac le cookies ',

  statement: ' Ár ráiteas cookies',

  save: ' Sábháil socruithe ',

  always_on: ' I gcónaí ar ',

  cookie_essential_title: ' Cookies riachtanacha suíomh Gréasáin ',

  cookie_essential_desc: ' Cuidíonn cookies riachtanacha le suíomh Gréasáin a úsáid trí fheidhmeanna bunúsacha mar nascleanúint leathanaigh agus rochtain ar chodanna slána den láithreán gréasáin a chumasú. Ní féidir leis an suíomh Gréasáin feidhmiú i gceart gan na cookies seo',

  cookie_performance_title: ' Cookies feidhmíochta ',

  cookie_performance_desc: ' Úsáidtear na cookies seo chun feidhmíocht agus feidhmiúlacht ár láithreáin ghréasáin a fheabhsú ach níl siad riachtanach lena n-úsáid. Mar shampla stórálann sé an teanga is fearr leat nó an réigiún ina bhfuil tú.',

  cookie_analytics_title: ' Cookies Analytics ',

  cookie_analytics_desc: ' Úsáidimid cookies anailíse chun cabhrú linn tomhas a dhéanamh ar an gcaoi a n-idirghníomhaíonn úsáideoirí le hábhar láithreán gréasáin, rud a chabhraíonn linn ár láithreáin ghréasáin agus ár bhfeidhmchlár a shaincheapadh duit d’fhonn d’eispéireas a fheabhsú.',

  cookie_marketing_title: ' Cookies mhargaíochta ',

  cookie_marketing_desc: ' Úsáidtear na cookies seo chun teachtaireachtaí fógraíochta a dhéanamh níos ábhartha duit féin agus do do leasanna. Is é atá i gceist ná fógraí atá ábhartha agus tarraingteach don úsáideoir aonair a thaispeáint agus ar an gcaoi sin níos luachmhaire d’fhoilsitheoirí agus d’fhógróirí tríú páirtí.'

}
