//Add strings


gdprCookieNoticeLocales.el = {

  description: ' Χρησιμοποιούμε cookies για να σας προσφέρουμε μια καλύτερη εμπειρία περιήγησης, να εξατομικεύσετε περιεχόμενο και διαφημίσεις, να παρέχουμε λειτουργίες κοινωνικών μέσων και να αναλύσουμε την επισκεψιμότητά μας. Διαβάστε σχετικά με το πώς χρησιμοποιούμε τα cookies και πώς μπορείτε να τα ελέγξετε κάνοντας κλικ στις Ρυθμίσεις cookie. Συμφωνείτε με τα cookie μας εάν συνεχίσετε να χρησιμοποιείτε αυτόν τον ιστότοπο.',

  settings: ' Ρυθμίσεις cookie ',

  accept: ' Αποδεχτείτε τα cookies',

  statement: ' Η δήλωση cookie μας',

  save: ' Αποθήκευση ρυθμίσεων',

  always_on: ' Πάντα ανοιχτό',

  cookie_essential_title: ' Βασικά cookie ιστότοπου',

  cookie_essential_desc: ' Τα απαραίτητα cookie βοηθούν τη χρήση ενός ιστότοπου, επιτρέποντας βασικές λειτουργίες όπως πλοήγηση σελίδων και πρόσβαση σε ασφαλείς περιοχές του ιστότοπου. Ο ιστότοπος δεν μπορεί να λειτουργήσει σωστά χωρίς αυτά τα cookie.',

  cookie_performance_title: ' Cookies απόδοσης',

  cookie_performance_desc: ' Αυτά τα cookies χρησιμοποιούνται για να βελτιώσουν την απόδοση και τη λειτουργικότητα των ιστοτόπων μας, αλλά δεν είναι απαραίτητα για τη χρήση τους. Για παράδειγμα, αποθηκεύει τη γλώσσα που προτιμάτε ή την περιοχή στην οποία βρίσκεστε.',

  cookie_analytics_title: 'Aναλυτικών cookies',

  cookie_analytics_desc: ' Χρησιμοποιούμε cookie αναλυτικών στοιχείων για να μας βοηθήσουν να μετρήσουμε τον τρόπο με τον οποίο οι χρήστες αλληλεπιδρούν με το περιεχόμενο του ιστότοπου, το οποίο μας βοηθά να προσαρμόσουμε τους ιστότοπους και την εφαρμογή μας για εσάς, προκειμένου να βελτιώσουμε την εμπειρία σας.',

  cookie_marketing_title: ' Μάρκετινγκ cookies',

  cookie_marketing_desc: ' Αυτά τα cookies χρησιμοποιούνται για να κάνουν τα διαφημιστικά μηνύματα πιο συναφή με εσάς και τα ενδιαφέροντά σας. Η πρόθεση είναι να εμφανιστούν διαφημίσεις που είναι συναφείς και ελκυστικές για τον κάθε χρήστη και επομένως πιο πολύτιμες για εκδότες και διαφημιστές τρίτων.'

}
