//Add strings


gdprCookieNoticeLocales.bg = {

    description: ' Ние използваме cookies, за да ви предложим по-добро изживяване при сърфиране, персонализиране на съдържание и реклами, за предоставяне на функции в социалните медии и за анализ на трафика ни. Прочетете как използваме cookies и как можете да ги контролирате, като щракнете върху Настройки на cookies. Вие се съгласявате с нашите cookies, ако продължите да използвате този уебсайт.',

    settings: ' Настройки на cookies',

    accept: ' Приемете cookies ',

    statement: ' Нашето изявление за cookies ',

    save: ' Запазване на настройките ',

    always_on: ' Винаги включен ',

    cookie_essential_title: ' Основни cookies на уебсайтове ',

    cookie_essential_desc: ' Необходимите cookies помагат да се направи уебсайт използваем чрез активиране на основни функции като навигация по страниците и достъп до защитени зони на уебсайта. Уебсайтът не може да функционира правилно без тези cookies.',

    cookie_performance_title: ' Cookies за ефективност ',

    cookie_performance_desc: ' Тези cookies се използват за подобряване на работата и функционалността на нашите уебсайтове, но не са от съществено значение за тяхната употреба. Например, той съхранява предпочитания от вас език или региона, в който се намирате.',

    cookie_analytics_title: ' „Cookies“ на Google Анализ ',

    cookie_analytics_desc: ' Ние използваме cookies за анализи, за да ни помогне да измерим как потребителите взаимодействат със съдържанието на уебсайта, което ни помага да персонализираме нашите уебсайтове и приложение за вас, за да подобрим вашето преживяване.',

    cookie_marketing_title: 'Маркетинг на cookies',

    cookie_marketing_desc: ' Тези cookies се използват, за да направят рекламните съобщения по-подходящи за вас и вашите интереси. Намерението е да се показват реклами, които са подходящи и ангажиращи за отделния потребител и по този начин по-ценни за издателите и рекламодателите от трети страни.'

}
