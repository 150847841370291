//Add strings


gdprCookieNoticeLocales.lv = {

  description: ' Šīs cookies tiek izmantotas, lai reklāmas ziņojumus padarītu atbilstošākus jums un jūsu interesēm. Paredzēts parādīt reklāmas, kas ir atbilstošas un saistošas atsevišķam lietotājam un tādējādi vērtīgākas izdevējiem un trešo personu reklāmdevējiem.',

  settings: ' Cookies iestatījumi ',

  accept: ' Pieņemt cookies ',

  statement: ' Mūsu sīkdatņu paziņojums ',

  save: ' Saglabāt iestatījumus ',

  always_on: ' Vienmēr ',

  cookie_essential_title: ' Būtiski vietnes cookies ',

  cookie_essential_desc: ' Nepieciešamie cookies palīdz padarīt vietni izmantojamu, ļaujot veikt tādas pamatfunkcijas kā navigācija lappusē un piekļuve drošām vietnes vietām. Vietne nevar pareizi darboties bez šīm sīkdatnēm.',

  cookie_performance_title: ' Izpildījuma cookies ',

  cookie_performance_desc: ' Šīs cookies tiek izmantotas, lai uzlabotu mūsu vietņu veiktspēju un funkcionalitāti, taču nav būtiskas to lietošanai. Piemēram, tajā tiek saglabāta vēlamā valoda vai reģions, kurā atrodaties.',

  cookie_analytics_title: ' Analytics cookies ',

  cookie_analytics_desc: ' Mēs izmantojam analītiskās cookies, lai palīdzētu mums izmērīt, kā lietotāji mijiedarbojas ar vietnes saturu, un tas palīdz mums pielāgot mūsu vietnes un lietojumprogrammas jums, lai uzlabotu jūsu pieredzi.',

  cookie_marketing_title: ' Mārketinga cookies ',

  cookie_marketing_desc: ' Šīs cookies tiek izmantotas, lai reklāmas ziņojumus padarītu atbilstošākus jums un jūsu interesēm. Paredzēts parādīt reklāmas, kas ir atbilstošas un saistošas atsevišķam lietotājam un tādējādi vērtīgākas izdevējiem un trešo personu reklāmdevējiem.'

}
