//Add strings


gdprCookieNoticeLocales.mt = {

  description: ' Aħna nużaw cookies biex noffrulek esperjenza ta ’browsing aħjar, tippersonalizza l-kontenut u r-reklami, biex nipprovdu karatteristiċi tal-midja soċjali u tanalizzaw it-traffiku tagħna. Aqra dwar kif nużaw il-cookies u kif tista tikkontrollahom billi tikklikkja Cookie Settings. Inti taqbel mal-cookies tagħna jekk tkompli tuża dan il-websajt.',

  settings: ' Is-settings tal-cookies ',

  accept: ' Aċċetta cookies ',

  statement: ' Dikjarazzjoni tal-cookie tagħna ',

  save: ' Issejvja s-settings ',

  always_on: ' Dejjem fuq ',

  cookie_essential_title: ' Cookies essenzjali tal-websajt ',

  cookie_essential_desc: ' Il-cookies meħtieġa jgħinu biex il-websajt tkun tista tintuża billi tippermetti funzjonijiet bażiċi bħan-navigazzjoni fil-paġna u l-aċċess għal żoni siguri tal-websajt. Il-websajt ma tistax taħdem sew mingħajr dawn il-cookies.',

  cookie_performance_title: ' Cookies ta ’prestazzjoni ',

  cookie_performance_desc: ' Dawn il-cookies jintużaw biex itejbu l-prestazzjoni u l-funzjonalità tal-websajts tagħna iżda mhumiex essenzjali għall-użu tagħhom. Pereżempju jaħżen il-lingwa preferita tiegħek jew ir-reġjun li fih int.',

  cookie_analytics_title: ' Cookies tal-Analytics ',

  cookie_analytics_desc: ' Aħna nużaw cookies analitiċi biex jgħinuna nkejlu kif l-utenti jinteraġixxu mal-kontenut tal-websajt, li jgħinna nagħmlu l-websajts tagħna u l-applikazzjoni għalik biex intejbu l-esperjenza tiegħek.',

  cookie_marketing_title: ' Cookies għall-kummerċjalizzazzjoni ',

  cookie_marketing_desc: ' Dawn il-cookies jintużaw biex jagħmlu messaġġi ta ’reklamar aktar rilevanti għalik u għall-interessi tiegħek. L-intenzjoni hija li jintwerew reklami li huma relevanti u li jidħlu għall-utent individwali u bhekk ikunu aktar siewja għall-pubblikaturi u r-reklamar tal-partijiet terzi.'

}
