//Add strings


gdprCookieNoticeLocales.sk = {

  description: ' Používame cookies, aby sme vám poskytli lepší zážitok z prehliadania, prispôsobili obsah a reklamy, poskytovali funkcie sociálnych médií a analyzovali našu návštevnosť. Kliknutím na položku Nastavenia súborov cookie si prečítajte, ako používame súbory cookie a ako ich môžete ovládať. Ak budete pokračovať v používaní tejto webovej stránky, súhlasíte s našimi súbormi cookie.',

  settings: ' Nastavenia súborov cookie ',

  accept: ' Prijímajte cookies ',

  statement: ' Naše vyhlásenie o súboroch cookie ',

  save: ' Uložiť nastavenia ',

  always_on: ' Vždy zapnutý ',

  cookie_essential_title: ' Základné súbory cookie webových stránok ',

  cookie_essential_desc: ' Potrebné cookies pomáhajú urobiť web použiteľným tým, že umožňujú základné funkcie, ako je navigácia po stránke a prístup k zabezpečeným oblastiam webu. Bez týchto súborov cookie nemôže webová stránka správne fungovať.',

  cookie_performance_title: ' Výkonové cookies ',

  cookie_performance_desc: ' Tieto súbory cookie sa používajú na zvýšenie výkonu a funkčnosti našich webových stránok, ale nie sú nevyhnutné na ich používanie. Napríklad ukladá váš preferovaný jazyk alebo oblasť, v ktorej sa nachádzate.',

  cookie_analytics_title: ' Súbory cookie Analytics ',

  cookie_analytics_desc: ' Analytické súbory cookie používame na to, aby sme mohli merať spôsob, akým používatelia interagujú s obsahom webových stránok, čo nám pomáha prispôsobiť naše webové stránky a aplikácie za účelom vylepšenia vašej práce.',

  cookie_marketing_title: 'Marketing cookies',

  cookie_marketing_desc: ' Tieto súbory cookie sa používajú na zvýšenie relevantnosti reklamných správ pre vás a vaše záujmy. Zámerom je zobrazovať reklamy, ktoré sú relevantné a pútavé pre jednotlivého používateľa, a tým sú pre vydavateľov a inzerentov tretích strán cennejšie.'

}
