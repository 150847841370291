//Add strings


gdprCookieNoticeLocales.sl = {

  description: ' Cookies uporabljamo za boljšo izkušnjo brskanja, prilagajanje vsebine in oglasov, zagotavljanje funkcij družbenih medijev in analizo našega prometa. Preberite, kako uporabljamo cookies in kako jih lahko nadzorujete s klikom na Nastavitve cookies. Če se še naprej uporabljate to spletno mesto, se strinjate z našimi cookies.',

  settings: ' Nastavitve cookies ',

  accept: ' Sprejmite cookies ',

  statement: ' Naša izjava o cookiesh ',

  save: ' Shrani nastavitve ',

  always_on: ' Vedno vklopljen ',

  cookie_essential_title: ' Bistveni cookies spletnega mesta ',

  cookie_essential_desc: ' Potrebni cookies pomagajo uporabiti spletno mesto z omogočanjem osnovnih funkcij, kot so navigacija po strani in dostop do varnih območij spletnega mesta. Spletno mesto ne more pravilno delovati brez teh cookies.',

  cookie_performance_title: ' Cookies uspešnosti ',

  cookie_performance_desc: ' Ti cookies se uporabljajo za izboljšanje učinkovitosti in funkcionalnosti naših spletnih strani, vendar niso bistveni za njihovo uporabo. Na primer, shrani želeni jezik ali regijo, v kateri se nahajate.',

  cookie_analytics_title: ' Cookies storitve Analytics ',

  cookie_analytics_desc: ' Cookies analitike uporabljamo za merjenje, kako uporabniki komunicirajo z vsebino spletnega mesta, kar nam pomaga pri prilagajanju naših spletnih mest in aplikacij za vas, da izboljšamo vašo izkušnjo.',

  cookie_marketing_title: ' Marketinški cookies ',

  cookie_marketing_desc: ' Ti cookies se uporabljajo za povečanje oglaševalskih sporočil za vas in vaše interese. Namen je prikazati oglase, ki so ustrezni in privlačni za posameznega uporabnika in s tem bolj dragoceni za založnike in tretje oglaševalce.'

}
